@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  color: #010101;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen,
    Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  background: #f1f1f1;
  -webkit-font-smoothing: antialiased;

  -moz-osx-font-smoothing: grayscale;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
